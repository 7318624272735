<template>
  <es-table class="employ-analytics" :headers="headers" :items="analytics" date-filter download="analytics.export"
            @update="getUserAnalytics" @downloadData="downloadData" :importFormats="importFormats">
    <div class="d-flex align-center">
      <h3 class="mr-7">Аналитика по сотрудникам</h3>
      <EsFastDatesRange />
    </div>
  </es-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import exportData from '@/utils/files/requestExport';
import EsFastDatesRange from "@/components/сommon/EsTable/EsFastDatesRange";

export default {
  name: "EmployeeAnalytics",
  components: {EsFastDatesRange},
  data: () => ({
    importFormats: ['xlsx'],
    headers: [
      {
        text: 'Имя сотрудника',
        value: 'user.name',
      },
      {
        text: 'Конверсия',
        value: 'conversion',
      },
      {
        text: 'Сделок активно',
        value: 'active_deals_count',
      },
      {
        text: 'Сделок отменено',
        value: 'cancelled_deals_count',
      },
      {
        text: 'Сделок завершено успешно',
        value: 'completed_deals_count',
      },
      {
        text: 'Новые клиенты',
        value: 'new_clients_count',
      },
      {
        text: 'Учитываемые клиенты в конверсии',
        value: 'processed_clients_count',
      },
      {
        text: 'Не учитываемые клиенты в конверсии',
        value: 'unprocessed_clients_count',
      },
      {
        text: 'Кол-во активных диалогов',
        value: 'active_chats_count',
      },
      {
        text: 'Выручка',
        value: 'sales_amount',
      },
      {
        text: 'План на месяц',
        value: 'monthly_plan',
      },
      {
        text: 'Прогресс выполнения плана',
        value: 'monthly_plan_progress',
      },
      {
        text: 'Средний чек',
        value: 'average_check',
      },
      {
        text: 'Активность',
        value: 'activity_time',
      },
    ],
  }),
  computed: {
    ...mapState("analytics", ["analytics", "userId"]),
  },
  methods: {
    ...mapActions({
      getUserAnalytics: 'analytics/getUserAnalytics',
      downloadReport: 'analytics/downloadReport',
    }),
    downloadData(payload) {
      if (this.userId) payload.user_id = this.userId;
      exportData(this.$store.dispatch, 'analytics/downloadReport', {name: "users", args: payload}, `Аналитика по сотрудникам ${this.$moment(new Date()).format('DD-MM-YYYY')}`);
    },
    getData() {
      let payload = {
        per_page: 10,
        page: 1,
      };
      if (this.userId) {
        payload.user_id = this.userId;
      }

      this.getUserAnalytics(payload);
    },
  },
  beforeDestroy() {
    this.$store.dispatch('analytics/setUserId', null);
  },
  watch: {
    userId(val) {
      if (!val) {
        this.getData();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.employ-analytics h3 {
  color: #FF8577;
}
</style>
